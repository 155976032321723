@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,800;1,900&display=swap');

/* src/components/Introduction.css */
.introduction-section {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Align the team name at the top */
    background-image: url('../assessts/we_are.jpeg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed; /* Parallax effect */
    height: 100vh; /* Full viewport height */
    max-height: 52rem; /* Limit to 57rem */
    color: #fff;
    position: relative;
    text-align: center;
    margin-bottom: 0;
}

.welcome-container {
    margin-top: 8rem; /* Adjust to control vertical spacing */
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
}

.welcome-text {
    font-family: "Roboto", sans-serif;
    font-size: 1.1rem;
    font-weight: 300;
    color: black;
    letter-spacing: 0.1rem;
    margin: 0;
}

.team-title {
    font-family: "Roboto", sans-serif;
    font-size: 8rem;
    font-weight: bold;
    color: black;
    letter-spacing: 1.5rem;
    margin: 0;
}

/* Tablet adjustments for iPad Pro and similar screens */
@media (min-width: 769px) and (max-width: 1024px) {
    .introduction-section {
        background-attachment: scroll; /* Avoid fixed backgrounds on tablets */
        background-position: center;
        max-height: 40rem;
        padding: 2rem 1rem; /* Add padding for better layout */
    }

    .welcome-container {
        margin-top: 6rem; /* Adjust vertical alignment */
    }

    .welcome-text {
        font-size: 0.9rem; /* Scale down slightly */
        letter-spacing: 0.08rem;
    }

    .team-title {
        font-size: 6rem; /* Scale down for smaller screens */
        letter-spacing: 1rem;
    }
}

/* Landscape mode for iPad */
@media (min-width: 769px) and (max-width: 1366px) and (orientation: landscape) {
    .introduction-section {
        background-size: contain;
        background-position: center;
        height: 80vh;
    }

    .welcome-container {
        margin-top: 6rem; /* Adjust vertical alignment */
    }

    .welcome-text {
        font-size: 0.9rem; /* Scale down slightly */
        letter-spacing: 0.08rem;
    }

    .team-title {
        font-size: 6rem; /* Scale down for smaller screens */
        letter-spacing: 1rem;
    }
}

/* Mobile adjustments */
@media (max-width: 768px) {
    .introduction-section {
        background-attachment: scroll; /* Disable parallax for mobile performance */
        /*padding-top: 80%; /* Adjust aspect ratio for mobile if needed */
        background-image: url('../assessts/we_are_2.jpeg');
        max-height: 17rem;
        background-position: center;
        
    }

    .welcome-container {
        margin-top: 2rem; /* Reduce top margin for mobile */
    }

    .welcome-text {
        font-size: 0.7rem;
        letter-spacing: 0.05rem;
    }

    .team-title {
        font-size: 2.4rem;
        letter-spacing: 0.2rem;
        /*text-shadow: 1px 1px 2px white;*/
    }
}