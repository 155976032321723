/* src/components/Header.css */

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.navbar-container {
    width: 100%;
    background-color: white;
    position: fixed;
    top: 0;
    z-index: 1000;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 12rem;
    margin: 0 auto;
    color: black;
}

.navbar-left {
    display: flex;
    align-items: center;
}

.team-logo {
    height: 85px;
    width: 84px;
    margin-right: 10px;
}

.team-text {
    display: flex;
    flex-direction: column;
    gap: 1px;
}

.team-slogan {
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-weight: 300;
    margin-top: 0;
}

.team-name {
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0;
    letter-spacing: 0.2rem;
}

/* Desktop Menu */
.desktop-menu {
    font-family: "Poppins", sans-serif;
    list-style: none;
    display: flex; /* Visible on desktop */
    gap: 35px;
}

.desktop-menu li {
    display: inline;
}

.desktop-menu li a {
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
    color: black;
}

/* Mobile Styles - Hidden on Desktop */
.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    gap: 4px;
}

.hamburger span {
    display: block;
    width: 25px;
    height: 2px;
    background-color: black;
}

.menu-links {
    display: none; /* Only displayed on mobile */
    flex-direction: column;
    position: absolute;
    top: 60px; /* Adjust based on header height */
    right: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 1rem;
}

.menu-links.open {
    display: flex; /* Show when open on mobile */
}

.menu-links li {
    padding: 0.5rem 0;
    list-style-type: none;
}

.menu-links li a {
    font-size: 16px;
    color: black;
}

/* Show hamburger on small screens, hide desktop menu */
@media (max-width: 768px) {
    .desktop-menu {
        display: none; /* Hide the desktop menu */
    }

    .hamburger {
        display: flex; /* Show hamburger icon */
    }

        /* Smaller Logo */
        .team-logo {
            height: 50px; /* Reduce logo size */
            width: 49px; /* Reduce logo size */
        }
    
        /* Smaller Team Name */
        .team-name {
            font-size: 16px; /* Adjust font size for mobile */
            letter-spacing: 0.1rem; /* Adjust letter spacing for readability */
        }
    
        /* Adjust the team slogan as well if needed */
        .team-slogan {
            font-size: 11px; /* Smaller font size for slogan on mobile */
        }

    .navbar {
        padding: 10px 1rem;
    }
}

/* Adjustments for iPad and similar tablet screens */
@media (min-width: 769px) and (max-width: 1024px) {
    .navbar {
        padding: 10px 6rem; /* Reduce padding to fit the smaller screen */
    }

    .team-logo {
        height: 70px; /* Scale logo size for tablets */
        width: 69px;
        margin-right: 8px; /* Adjust spacing */
    }

    .team-name {
        font-size: 18px; /* Slightly smaller font for tablet */
        letter-spacing: 0.15rem; /* Adjust spacing for better readability */
    }

    .team-slogan {
        font-size: 12px; /* Reduce slogan font size for balance */
    }

    .desktop-menu {
        gap: 20px; /* Reduce spacing between menu items */
    }

    .desktop-menu li a {
        font-size: 13px; /* Slightly smaller font size for tablet */
    }
}