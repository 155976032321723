@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,800;1,900&display=swap');

.players-section {
    font-family: "Roboto", sans-serif;
    text-align: center;
}

.players-stars {
    font-size: 4rem;
    font-weight: bold;
    color: black;
    letter-spacing: 1.1rem;
    margin: 0;
}
.players-section > h3 {
    font-size: 2rem;
    font-weight: 300;
}

/* Mobile adjustments */
@media (max-width: 768px) {
    .players-stars {
        font-size: 2rem;
    }

    .players-section > h2 {
        font-size: 1rem;    
        margin-bottom: 2rem;
    }

    .players-section > h3 {
        font-size: 1.2rem;
    }
}