/* src/components/About.css */
.about-section {
    display: flex;
    justify-content: space-between;
    align-items: top;
    padding-top: 0;
    padding-left: 2rem;
    background-color: #fff;
    color: #000;
    max-height: 44rem;
    margin: 0;
    padding-bottom: 0;
}

.about-text {
    flex: 1;
    padding-right: 2rem;
    padding-top: 80px;
}

.about-text h2 {
    font-size: 1rem;
    font-weight: 300;
    margin: 0 0 0.5rem 0;
    border-top: 1px solid #000;
    padding-top: 0.5rem;
}

.about-text h1 {
    font-size: 3rem;
    font-weight: bold;
    margin: 0 0 1rem 0;
}

.about-text p {
    font-size: 1rem;
    line-height: 1.5;
}

.about-video {
    flex: 1;
    overflow: hidden;
}

.about-video video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Mobile adjustments */
@media (max-width: 768px) {
    .about-section {
        flex-direction: column;
        padding-left: 0;
    }

    .about-text {
        padding-left: 2rem;
    }
}