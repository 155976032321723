@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,800;1,900&display=swap');

.contact-section {
    font-family: "Roboto", sans-serif;
    background-color:  #17a589;
    padding: 1rem;
    padding-top: 3rem;
    color: white;
    height: 100vh; /* Full viewport height */
    max-height: 12rem; /* Limit to 57rem */
}

.contact-section > h1 {
    font-size: 2rem;
    font-weight: bold;
    color: black;
    letter-spacing: 0.5rem;
}

.contact-section > h2 {
    font-size: 1rem;
    margin-bottom: 2rem;
}

.contact-section > h3 {
    font-size: 1rem;
}